import React from "react"
import PropTypes from "prop-types"

export default class Endorsement extends React.Component {
  render() {
    return (
      <div className="media">
        {this.props.imageUrl && <img src={this.props.imageUrl} className="mr-3" alt={this.props.name} />}
        <div className="media-body">
          {this.props.children}
          <div className="endorsementSource">&mdash; <span className="endorsementName">{this.props.name}</span>, <span className="endorsementBio">{this.props.bio}</span></div>
        </div>
      </div>
    );
  }
}

Endorsement.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  bio: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired
}
