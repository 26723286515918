import React from "react"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class About extends React.Component {
  render() {
    return (
      <section id="about">
          <Container>
            <Row>
              <Col>
                <h1>ABOUT THE AUTHOR</h1>
                <img src="/Ron-Hutchcraft-2.jpg" alt="Ron Hutchcraft" />
                <div className="aboutCallout">
                  <p>Ron Hutchcraft is a veteran ministry leader and speaker, and founder and president of Ron Hutchcraft Ministries and 
                  On Eagle's Wings Native American youth outreach.</p>
                  <p>He is the author of <em>A Life That Matters, Peaceful Living in a Stressful World, 
                  The Battle for a Generation,</em> and more. His popular radio feature, <em>A Word with You</em>, is heard daily on over 1,300 outlets
                  and in the world's five most-spoken languages. Ron and his late wife Karen have three children and nine grandchildren.</p>
                </div>
              </Col>
            </Row>
          </Container>
      </section>
    );
  }
}
