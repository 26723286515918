import React from "react"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class PreCover extends React.Component {
  render() {
    return (
      <section id="precover">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={11} xl={10}>
              <img src="/Ron-Hutchcraft.jpg" alt="Ron Hutchcraft" />
              <h1>NEW BOOK</h1>
              <h2>BY RON HUTCHCRAFT</h2>
              <h3>HOPE WHEN YOUR HEART IS BREAKING</h3>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
