import React from "react"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import youtube from 'assets/img/youtube.png'

export default class More extends React.Component {
  render() {
    return (
      <section id="more">
          <h1>More from Ron Hutchcraft</h1>
          <Container>
            <Row className="justify-content-center">
              <Col xs={11} sm={12} md={11} lg={8} xl={7}>
                <Container>
                  <Row className="justify-content-center">
                    <Col xs={12} className="pb-1"><a className="noimage" href="https://hutchcraft.com">
                      Hutchcraft.com
                    </a></Col>

                    <Col xs={12} className="pb-3"><a href="https://hutchcraft.com/subscribe">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="blog" className="svg-inline--fa fa-blog fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M172.2 226.8c-14.6-2.9-28.2 8.9-28.2 23.8V301c0 10.2 7.1 18.4 16.7 22 18.2 6.8 31.3 24.4 31.3 45 0 26.5-21.5 48-48 48s-48-21.5-48-48V120c0-13.3-10.7-24-24-24H24c-13.3 0-24 10.7-24 24v248c0 89.5 82.1 160.2 175 140.7 54.4-11.4 98.3-55.4 109.7-109.7 17.4-82.9-37-157.2-112.5-172.2zM209 0c-9.2-.5-17 6.8-17 16v31.6c0 8.5 6.6 15.5 15 15.9 129.4 7 233.4 112 240.9 241.5.5 8.4 7.5 15 15.9 15h32.1c9.2 0 16.5-7.8 16-17C503.4 139.8 372.2 8.6 209 0zm.3 96c-9.3-.7-17.3 6.7-17.3 16.1v32.1c0 8.4 6.5 15.3 14.8 15.9 76.8 6.3 138 68.2 144.9 145.2.8 8.3 7.6 14.7 15.9 14.7h32.2c9.3 0 16.8-8 16.1-17.3-8.4-110.1-96.5-198.2-206.6-206.7z"></path></svg>
                      Subscribe: Ron's Daily Email Devotional or Blog
                    </a></Col>

                    <Col xs={12} className="pb-3"><a href="https://www.facebook.com/ronhutchcraftministries" target="__blank" rel="noreferrer">
                      <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-facebook-f fa-w-14 fa-9x"><defs className=""><clipPath id="clip-L6vVLlTsH7iL" className=""><path fill="currentColor" d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z" className=""></path></clipPath><mask x="0" y="0" width="100%" height="100%" id="mask-l8TXipSpNKwd" maskUnits="userSpaceOnUse" maskContentUnits="userSpaceOnUse" className=""><rect x="0" y="0" width="100%" height="100%" fill="white" className=""></rect><g transform="translate(224 256)" className=""><g transform="translate(0, 0)  scale(0.5625, 0.5625)  rotate(0 0 0)" className=""><path fill="black" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" transform="translate(-160 -256)" className=""></path></g></g></mask></defs><rect fill="currentColor" clipPath="url(#clip-L6vVLlTsH7iL)" mask="url(#mask-l8TXipSpNKwd)" x="0" y="0" width="100%" height="100%" className=""></rect></svg>
                      @RonHutchcraftMinistries
                    </a></Col>

                    <Col xs={12} className="pb-3"><a href="https://www.instagram.com/ronhutchcraft/" target="__blank" rel="noreferrer">
                      <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-instagram fa-w-14 fa-9x"><defs className=""><clipPath id="clip-aOtmKAyS9nHl" className=""><path fill="currentColor" d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z" className=""></path></clipPath><mask x="0" y="0" width="100%" height="100%" id="mask-xSRAw3eD9Z4W" maskUnits="userSpaceOnUse" maskContentUnits="userSpaceOnUse" className=""><rect x="0" y="0" width="100%" height="100%" fill="white" className=""></rect><g transform="translate(224 256)" className=""><g transform="translate(0, 0)  scale(0.5625, 0.5625)  rotate(0 0 0)" className=""><path fill="black" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" transform="translate(-224 -256)" className=""></path></g></g></mask></defs><rect fill="currentColor" clipPath="url(#clip-aOtmKAyS9nHl)" mask="url(#mask-xSRAw3eD9Z4W)" x="0" y="0" width="100%" height="100%" className=""></rect></svg>
                      @RonHutchcraft
                    </a></Col>

                    <Col xs={12} className="pb-3"><a href="https://twitter.com/ronhutchcraft" target="__blank" rel="noreferrer">
                      <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-twitter fa-w-14 fa-9x"><defs className=""><clipPath id="clip-JOTbE9rRL7x3" className=""><path fill="currentColor" d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z" className=""></path></clipPath><mask x="0" y="0" width="100%" height="100%" id="mask-uXUxrfpn3nO4" maskUnits="userSpaceOnUse" maskContentUnits="userSpaceOnUse" className=""><rect x="0" y="0" width="100%" height="100%" fill="white" className=""></rect><g transform="translate(224 256)" className=""><g transform="translate(0, 0)  scale(0.5625, 0.5625)  rotate(0 0 0)" className=""><path fill="black" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" transform="translate(-256 -256)" className=""></path></g></g></mask></defs><rect fill="currentColor" clipPath="url(#clip-JOTbE9rRL7x3)" mask="url(#mask-uXUxrfpn3nO4)" x="0" y="0" width="100%" height="100%" className=""></rect></svg>
                      @RonHutchcraft
                    </a></Col>

                    <Col xs={12} className="pb-3"><a href="https://www.youtube.com/user/HutchcraftMinistries" target="__blank" rel="noreferrer">
                      <img src={youtube} alt="YouTube" />
                      @HutchcraftMinistries
                    </a></Col>

                    <Col xs={12} className="pb-3"><a href="mailto:ron@hutchcraft.com">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope-square" className="svg-inline--fa fa-envelope-square fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM178.117 262.104C87.429 196.287 88.353 196.121 64 177.167V152c0-13.255 10.745-24 24-24h272c13.255 0 24 10.745 24 24v25.167c-24.371 18.969-23.434 19.124-114.117 84.938-10.5 7.655-31.392 26.12-45.883 25.894-14.503.218-35.367-18.227-45.883-25.895zM384 217.775V360c0 13.255-10.745 24-24 24H88c-13.255 0-24-10.745-24-24V217.775c13.958 10.794 33.329 25.236 95.303 70.214 14.162 10.341 37.975 32.145 64.694 32.01 26.887.134 51.037-22.041 64.72-32.025 61.958-44.965 81.325-59.406 95.283-70.199z"></path></svg>
                      Email:  Ron@Hutchcraft.com
                    </a></Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
      </section>
    );
  }
}
