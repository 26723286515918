import React from "react"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class News extends React.Component {
  render() {
    return (
      <section id="news">
          <Container>
            <Row className="justify-content-center">
              <Col xs={12}>
                <h1>NEWS</h1>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={12} sm={10} md={8} lg={6} className="newsItem">
                <div className="newsDate"><p>06 <span>Apr</span></p></div>
                Listen to Ron's interview on <a href="http://www.100huntley.com/watch?id=230228&title=hope-when-your-heart-is-breaking--ron-hutchcraft-" target="_blank" rel="noreferrer">100 Huntley Street</a>
              </Col>
              <Col xs={12} sm={10} md={8} lg={6} className="newsItem">
                <div className="newsDate"><p>09 <span>Mar</span></p></div>
                Listen to Ron's interview with Rabbi Eric Walker: <a href="https://www.youtube.com/watch?v=BSBfR8z-2Gc" target="_blank" rel="noreferrer">Ron Hutchcraft &amp; Rabbi Walker</a>
              </Col>
              <Col xs={12} sm={10} md={8} lg={6} className="newsItem">
                <div className="newsDate"><p>07 <span>Jan</span></p></div>
                Listen to Chris Fabry Live: <a href="https://www.moodyradio.org/programs/chris-fabry-live/2021/01/2021.01.07-hope-when-your-heart-is-breaking/" target="_blank" rel="noreferrer">Hope When Your Heart Is Breaking</a>
              </Col>
              <Col xs={12} sm={10} md={8} lg={6} className="newsItem">
                <div className="newsDate"><p>05 <span>Jan</span></p></div>
                Listen to  Dr Carol Ministries: <a href="https://www.drcarolministries.com/choosing-hope-what-it-is-and-how-to-find-it/" target="_blank" rel="noreferrer">Choosing Hope - What It Is and How to Find It</a>
              </Col>
              <Col xs={12} sm={10} md={8} lg={6} className="newsItem">
                <div className="newsDate"><p>16 <span>Dec</span></p></div>
                Listen to the Janet Mefferd Today radio interview: <a href="https://bottradionetwork.com/ministry/janet-mefferd-today/2020-12-16-ron-hutchcraft-grief-and-hope/" target="_blank" rel="noreferrer">Ron Hutchcraft (Grief and Hope)</a>
              </Col>
              <Col xs={12} sm={10} md={8} lg={6} className="newsItem">
                <div className="newsDate"><p>14 <span>Dec</span></p></div>
                Listen to interview with Janet Parshall / In the Market, on Moody Radio: <a href="https://www.moodyradio.org/programs/in-the-market-with-janet-parshall/2020/12/12.14.2020-ethical-medicine-and-clear-history---hope-for-hurting-hearts/" target="_blank" rel="noreferrer">"Hope for Hurting Hearts."</a>
              </Col>
              <Col xs={12} sm={10} md={8} lg={6} className="newsItem">
                <div className="newsDate"><p>09 <span>Dec</span></p></div>
                Interview: <a href="https://www.moodyradio.org/programs/morning-shows/eric-and-brigitte/2020/12/1209/" target="_blank" rel="noreferrer">Mornings with Eric and Brigitte, Moody Radio South Florida.  “A Pathway to Hope.”</a>
              </Col>
              <Col xs={12} sm={10} md={8} lg={6} className="newsItem">
                <div className="newsDate"><p>09 <span>Dec</span></p></div>
                READ the Charisma News article: <a href="https://www.charismanews.com/culture/83622-when-grief-strikes-like-a-hammer-will-you-use-it-to-build-or-destroy" target="_blank" rel="noreferrer">When Grief Strikes Like a Hammer, Will You Use It to Build or Destroy?</a>
              </Col>
              <Col xs={12} sm={10} md={8} lg={6} className="newsItem">
                <div className="newsDate"><p>09 <span>Dec</span></p></div>
                Listen to the Charisma News Podcast: <a href="https://www.charismapodcastnetwork.com/show/charismanews/830aa654-f6c0-4d5d-88ae-b48538ad11d1/Family-Matters%3A-A-Chat-With-Ron-Hutchcraft" target="_blank" rel="noreferrer">Family Matters A Chat with Ron Hutchcraft</a>
              </Col>
            </Row>
            <Row className="justify-content-center pt-5">
              <Col xs={12} sm={10} md={8} lg={6}>
                <h3 className="pt-5">JOIN THE EMAIL LIST FOR UPDATES!</h3>
                <iframe src="https://info.hutchcraft.com/l/836243/2020-10-30/6p252" title="Get Email Updates" width="100%" height="435" type="text/html"></iframe>
              </Col>
            </Row>
          </Container>
      </section>
    );
  }
}
