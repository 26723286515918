import React from "react"
import PropTypes from "prop-types"
import "../scss/style.scss"
import Header from "./Header"
import Container from "react-bootstrap/Container"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const getScrollNode = (element) => {
  return element.ownerDocument.scrollingElement || element.ownerDocument.documentElement
}

const isScrolled = (element) => {
  const scrollNode = getScrollNode(element)
  return scrollNode.scrollTop > 0
}

export default class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.siteContainer = React.createRef()
    this.state = {
      scrolled: false,
    }
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
    const element = this.siteContainer.current
    this.setState({
      scrolled: isScrolled(element),
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll() {
    const element = this.siteContainer.current
    this.setState({
      scrolled: isScrolled(element),
    })
  }

  render() {
    let className = (this.state.scrolled ? "navbar-scrolled" : "");
    return (
      <div className={className} ref={this.siteContainer} id="page-top" style={{overflow:"hidden"}}>
        <Container fluid>
          <Row style={{margin: 0}}>
            <Col style={{padding: 0}}>
              <Header></Header>
              <main>{this.props.children}</main>
              <footer></footer>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}