import React from "react"
import PropTypes from "prop-types"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import storeLogo from 'assets/img/rhm-store-logo.jpg';
import hopeDonate from 'assets/img/hope-donate.jpg';
import audiobook from 'assets/img/audiobook.jpg';

export default class Order extends React.Component {
  render() {
    return (
      <section id="order">
          <Container>
            <Row>
              <Col>
                <h1>ORDER NOW!</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4 style={{color:"#000"}}>SHIPPING NOW THROUGH:</h4>
                <h6><em>In Stock &ndash;&ndash; now available!</em></h6>
              </Col>
            </Row>
            <Row>
              <Col className="pt-3 pb-3">
                <a className="orderLink" href="https://store.hutchcraft.com/discount/3OFF?redirect=%2Fproducts%2Fhope-when-your-heart-is-breaking" target="__blank">
                  <div className="img-fluid">
                    <img src={storeLogo} alt="Ron Hutchcraft Ministries Store" />
                  </div>
                </a>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={10} sm={6} md={5} lg={4} xl={3}>
                <p className="px-3 pt-3" style={{color:"#000"}}>Use coupon code 3OFF to receive a $3.00 discount!</p>
                <p><em>Plus: Free Shipping!</em></p>
              </Col>
            </Row>
            <Row className="pt-5">
              <Col>
                <p><a href="https://www.amazon.com/Hope-When-Your-Heart-Breaking/dp/B08VF3R59G/ref=zg_bsnr_7259412011_18?_encoding=UTF8&psc=1&refRID=VRKH7T46BYNHJ8RHMZQH" target="__blank">Audible Audiobook – Unabridged<br/>(read by Ron Hutchcraft)</a></p>
              </Col>
            </Row>

            <Row>
              <Col className="pt-1 pb-4">
                <a className="orderLink audible" href="https://www.amazon.com/Hope-When-Your-Heart-Breaking/dp/B08VF3R59G/ref=zg_bsnr_7259412011_18?_encoding=UTF8&psc=1&refRID=VRKH7T46BYNHJ8RHMZQH" target="__blank">
                  <div className="img-fluid">
                    <img src={audiobook} alt="Audible Audiobook – Unabridged (read by Ron Hutchcraft)" title="Audible Audiobook – Unabridged (read by Ron Hutchcraft)" />
                  </div>
                </a>
              </Col>
            </Row>

            <Row className="pt-5">
              <Col>
                <p className="px-1">Also available at&hellip;</p>
              </Col>
            </Row>

            <Row className="pt-1 pb-1">
              <Col>
                <a className="orderLink" href="https://www.amazon.com/Hope-When-Your-Heart-Breaking/dp/0736981411/ref=sr_1_1?dchild=1&keywords=hope+when+your+heart+is+breaking+hutchcraft&qid=1603733687&sr=8-1" target="__blank">
                  <div className="img-fluid">
                    <svg width="175" height="34" viewBox="0 0 175 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M70.4223 26.2997C63.8464 31.1619 54.2814 33.7524 46.0316 33.7524C34.4739 33.7524 24.1118 29.488 16.2206 22.394C15.6228 21.836 16.1409 21.0788 16.8982 21.5172C25.3871 26.4591 35.8688 29.4083 46.7091 29.4083C54.0024 29.4083 62.053 27.8938 69.4658 24.7454C70.5817 24.307 71.5382 25.5026 70.4223 26.2997Z" fill="#000"></path>
                      <path fillRule="evenodd" clipRule="evenodd" d="M73.1723 23.1909C72.3353 22.1148 67.5927 22.6728 65.4804 22.9518C64.8428 23.0315 64.7232 22.4735 65.321 22.075C69.1071 19.4047 75.2845 20.2018 76.0019 21.0786C76.7193 21.9554 75.8027 28.1727 72.2556 31.1219C71.6977 31.5603 71.1796 31.3211 71.4187 30.7233C72.2158 28.6908 74.0092 24.267 73.1723 23.1909Z" fill="#000"></path>
                      <path fillRule="evenodd" clipRule="evenodd" d="M65.6 3.74625V1.15573C65.6 0.757192 65.879 0.518066 66.2376 0.518066H77.7954C78.154 0.518066 78.4729 0.797046 78.4729 1.15573V3.34771C78.4729 3.7064 78.154 4.18465 77.5961 4.98173L71.618 13.5105C73.8498 13.4707 76.2012 13.7895 78.1939 14.9054C78.6323 15.1446 78.7519 15.5431 78.7917 15.9018V18.6517C78.7917 19.0503 78.3932 19.4887 77.9548 19.2495C74.4078 17.3764 69.6651 17.1771 65.7594 19.2894C65.3609 19.4887 64.9225 19.0901 64.9225 18.6916V16.0612C64.9225 15.6627 64.9225 14.9453 65.3609 14.3076L72.3353 4.38392H66.3174C65.9188 4.38392 65.6398 4.10494 65.6 3.74625Z" fill="#000"></path>
                      <path fillRule="evenodd" clipRule="evenodd" d="M23.4741 19.8473H19.9669C19.6481 19.8075 19.3691 19.5683 19.3293 19.2495V1.23543C19.3293 0.87674 19.6481 0.597761 20.0068 0.597761H23.2748C23.6335 0.597761 23.8726 0.87674 23.9125 1.19557V3.54697H23.9922C24.8291 1.27528 26.4632 0.199219 28.6153 0.199219C30.8073 0.199219 32.2022 1.27528 33.1587 3.54697C33.9956 1.27528 35.9485 0.199219 38.0209 0.199219C39.4955 0.199219 41.0896 0.797031 42.086 2.19193C43.2019 3.70639 42.9628 5.89837 42.9628 7.85122V19.2495C42.9628 19.6082 42.644 19.8872 42.2853 19.8872H38.7781C38.4194 19.8473 38.1404 19.5683 38.1404 19.2495V9.68451C38.1404 8.92728 38.2201 7.01428 38.0607 6.29691C37.7818 5.10128 37.0245 4.7426 35.9883 4.7426C35.1514 4.7426 34.2347 5.30055 33.876 6.2172C33.5174 7.13385 33.5572 8.6483 33.5572 9.68451V19.2495C33.5572 19.6082 33.2384 19.8872 32.8797 19.8872H29.3725C29.0138 19.8473 28.7349 19.5683 28.7349 19.2495V9.68451C28.7349 7.6918 29.0537 4.70274 26.5827 4.70274C24.0719 4.70274 24.1516 7.57224 24.1516 9.68451V19.2495C24.1516 19.5285 23.8726 19.8473 23.4741 19.8473Z" fill="#000"></path>
                      <path fillRule="evenodd" clipRule="evenodd" d="M88.4364 0.159424C93.6573 0.159424 96.4471 4.66295 96.4471 10.3621C96.4471 15.862 93.3385 20.2459 88.3966 20.2459C83.2952 20.2061 80.5054 15.7424 80.5054 10.1628C80.5054 4.54338 83.3351 0.159424 88.4364 0.159424ZM88.4763 3.86586C85.8858 3.86586 85.7263 7.41288 85.7263 9.60486C85.7263 11.7968 85.6865 16.4996 88.4364 16.4996C91.1465 16.4996 91.3059 12.7135 91.3059 10.4019C91.3059 8.88749 91.2262 7.0542 90.7878 5.61945C90.3494 4.34411 89.5922 3.86586 88.4763 3.86586Z" fill="#000"></path>
                      <path fillRule="evenodd" clipRule="evenodd" d="M103.222 19.8473H99.7151C99.3565 19.8075 99.0775 19.5285 99.0775 19.2097V1.15572C99.1173 0.836886 99.3963 0.557906 99.755 0.557906H103.023C103.342 0.557906 103.581 0.797031 103.661 1.07601V3.82595H103.74C104.737 1.35499 106.092 0.199219 108.523 0.199219C110.077 0.199219 111.632 0.757177 112.628 2.31149C113.545 3.74624 113.545 6.17735 113.545 7.93093V19.2894C113.505 19.6082 113.226 19.8473 112.867 19.8473H109.36C109.041 19.8075 108.762 19.5683 108.722 19.2894V9.48524C108.722 7.49253 108.961 4.62303 106.53 4.62303C105.693 4.62303 104.896 5.18099 104.498 6.05778C104.019 7.1737 103.94 8.24976 103.94 9.48524V19.2097C103.9 19.5285 103.621 19.8473 103.222 19.8473Z" fill="#000"></path>
                      <path fillRule="evenodd" clipRule="evenodd" d="M115.737 18.4128C115.737 17.4962 116.494 16.7788 117.45 16.7788C118.407 16.7788 119.164 17.4962 119.164 18.4128C119.164 19.3295 118.407 20.0469 117.45 20.0469C116.494 20.0469 115.737 19.2896 115.737 18.4128Z" fill="#000"></path>
                      <path d="M152.96 19.8473C152.602 19.8473 152.323 19.5683 152.323 19.2096V1.15565C152.363 0.836819 152.602 0.597694 152.96 0.55784H154.236C154.594 0.55784 154.873 0.836819 154.913 1.15565V3.78603C155.83 1.71361 157.544 0.0795898 159.696 0.0795898H159.815H159.935C162.207 0.0795898 163.92 1.75347 164.518 4.22442C165.475 1.79332 167.308 0.0795898 169.739 0.0795898H169.859H170.018C171.732 0.0795898 173.366 1.19551 174.203 2.86938C175.04 4.46355 175 6.57582 175 8.36926V19.2096C175 19.5683 174.721 19.8473 174.362 19.8473H172.808C172.489 19.8473 172.21 19.6081 172.17 19.2893V8.3294C172.17 7.05407 172.25 5.65917 171.692 4.5034C171.174 3.30778 170.137 2.55055 169.022 2.51069C167.786 2.5904 166.67 3.4672 165.953 4.66282C164.996 6.21713 165.036 7.61203 165.036 9.44532V19.2893C164.996 19.6081 164.717 19.8074 164.398 19.8473H162.844C162.485 19.8473 162.207 19.5683 162.207 19.2096V7.65188C162.207 6.57582 162.127 5.34034 161.649 4.38384C161.091 3.30778 160.054 2.5904 158.978 2.51069C157.862 2.5904 156.746 3.42734 156.109 4.4237C155.272 5.65917 155.073 7.21349 155.073 8.80765V19.2096C155.073 19.5683 154.794 19.8473 154.435 19.8473H152.96Z" fill="#000"></path>
                      <path d="M142.359 20.2059C137.377 20.2059 135.146 15.1046 135.146 10.0431C135.146 4.70267 137.776 0.0795898 142.798 0.0795898H142.917H143.037C147.939 0.0795898 150.37 5.02151 150.37 10.083C150.37 15.4633 147.7 20.2059 142.638 20.2059H142.519H142.359ZM142.718 17.735C144.352 17.6951 145.667 16.6589 146.424 14.9851C147.102 13.4706 147.261 11.717 147.261 10.0431C147.261 8.20984 147.062 6.3367 146.225 4.78238C145.468 3.42734 144.153 2.55055 142.758 2.51069C141.203 2.55055 139.808 3.62661 139.131 5.22078C138.493 6.61568 138.294 8.48882 138.294 10.0431C138.294 11.7967 138.533 13.7894 139.25 15.3039C139.928 16.7386 141.283 17.6951 142.718 17.735Z" fill="#000"></path>
                      <path d="M126.776 17.6553C129.008 17.5756 130.204 15.7821 130.682 13.4706C130.762 13.1916 131.001 12.9525 131.32 12.9525H132.794C133.153 12.9525 133.472 13.2315 133.432 13.5503C132.754 17.4959 130.363 20.2059 126.975 20.2059H126.856H126.736C121.794 20.2059 119.642 15.2242 119.642 10.2026C119.642 5.18092 121.794 0.0795898 126.776 0.0795898H126.896H127.015C130.443 0.0795898 132.914 2.70996 133.432 6.69538C133.432 6.97436 133.153 7.25334 132.834 7.2932H131.28C130.961 7.25334 130.762 6.97436 130.682 6.65553C130.323 4.46355 129.048 2.67011 126.936 2.63026C123.588 2.74982 122.591 6.89465 122.591 10.083C122.631 13.0721 123.428 17.5756 126.776 17.6553Z" fill="#000"></path>
                      <path fillRule="evenodd" clipRule="evenodd" d="M56.3937 11.199C56.3937 12.5541 56.4335 13.7098 55.756 14.9453C55.198 15.9417 54.3212 16.5395 53.3249 16.5395C51.9698 16.5395 51.1728 15.5033 51.1728 13.9888C51.1728 10.9998 53.843 10.4418 56.3937 10.4418V11.199ZM59.9407 19.7677C59.7016 19.9669 59.3827 20.0068 59.1037 19.8474C57.948 18.8909 57.7088 18.4126 57.0712 17.496C55.1582 19.4488 53.7633 20.0466 51.2923 20.0466C48.3431 20.0466 46.0316 18.2134 46.0316 14.5866C46.0316 11.7171 47.5859 9.80413 49.7779 8.84763C51.6909 8.01069 54.3611 7.85127 56.3937 7.61215V7.17375C56.3937 6.33681 56.4734 5.34046 55.9553 4.62308C55.5169 3.98542 54.7198 3.70644 54.0024 3.70644C52.6474 3.70644 51.4517 4.38396 51.1728 5.81871C51.093 6.13754 50.8938 6.45638 50.5749 6.45638L47.1873 6.09769C46.9084 6.01798 46.5895 5.81871 46.6692 5.38031C47.4265 1.23548 51.1728 0 54.5205 0C56.2342 0 58.4661 0.438396 59.8211 1.75358C61.5348 3.34775 61.3754 5.49988 61.3754 7.81142V13.2714C61.3754 14.9055 62.053 15.6228 62.6906 16.5395C62.9297 16.8583 62.9696 17.2569 62.6906 17.4561C61.9334 18.0539 60.6581 19.1699 59.9407 19.7677Z" fill="#000"></path>
                      <path fillRule="evenodd" clipRule="evenodd" d="M10.3621 11.199C10.3621 12.5541 10.4019 13.7098 9.72442 14.9453C9.16646 15.9417 8.28967 16.5395 7.29331 16.5395C5.93827 16.5395 5.14119 15.5033 5.14119 13.9888C5.14119 10.9998 7.81142 10.4418 10.3621 10.4418V11.199ZM13.9091 19.7677C13.67 19.9669 13.3511 20.0068 13.0722 19.8474C11.9164 18.8909 11.6773 18.4126 11.0396 17.496C9.12661 19.4488 7.73171 20.0467 5.26075 20.0467C2.31154 20.0865 0 18.2532 0 14.5866C0 11.7171 1.55431 9.80413 3.74629 8.84763C5.65929 8.01069 8.32952 7.85127 10.3621 7.61215V7.17375C10.3621 6.33681 10.4418 5.34046 9.92369 4.62308C9.48529 3.98542 8.68821 3.74629 7.97084 3.74629C6.61579 3.74629 5.42017 4.42381 5.14119 5.85856C5.06148 6.1774 4.86221 6.49623 4.54338 6.49623L1.15577 6.13754C0.876792 6.05784 0.557958 5.85856 0.637667 5.42017C1.35504 1.23548 5.10134 0 8.44909 0C10.1628 0 12.3946 0.438396 13.7497 1.75358C15.4634 3.34775 15.304 5.49988 15.304 7.81142V13.2714C15.304 14.9055 15.9815 15.6228 16.6192 16.5395C16.8583 16.8583 16.8982 17.2569 16.6192 17.4561C15.862 18.0539 14.5866 19.1699 13.9091 19.7677Z" fill="#000"></path>
                    </svg>
                  </div>
                </a>
                <span>&nbsp;</span>
                <a className="orderLink" href="https://www.barnesandnoble.com/w/hope-when-your-heart-is-breaking-ron-hutchcraft/1136921991?ean=9780736981415" target="__blank">
                  <div className="img-fluid">
                    <svg width="175" height="44" viewBox="0 0 175 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.31364 4.31364V11.4535H5.42924C6.61921 11.4535 6.84233 10.4122 6.84233 8.25542C6.84233 5.35486 6.54484 4.31364 5.13175 4.31364H4.31364ZM4.31364 14.5771V23.3532H5.42924C6.76796 23.3532 7.13982 22.5351 7.13982 18.742C7.13982 15.9902 6.84233 14.5771 5.42924 14.5771H4.31364ZM11.751 19.1883C11.751 24.32 9.44539 26.1793 5.57799 26.1793H0V1.41309H5.57799C9.89163 1.41309 11.0072 4.01615 11.0072 8.25542C11.0072 11.3047 9.89163 12.9409 7.95793 13.0153C10.0404 13.0153 11.751 15.1721 11.751 19.1883Z" fill="#000"></path>
                      <path d="M32.5754 4.53676V11.8253H33.9141C35.1041 11.8253 35.4016 11.0816 35.4016 8.77603C35.4016 5.13174 34.7322 4.61113 33.3935 4.61113H32.5754V4.53676ZM36.2941 13.5359C39.0459 14.354 39.4178 17.6264 39.4178 20.6757C39.4178 22.9813 39.4921 25.0637 39.7896 26.2537H35.2529C35.1041 25.2869 34.9554 24.7663 34.9554 22.5351C34.9554 21.5682 34.9554 20.6014 34.9554 19.337C34.9554 17.3289 34.286 15.9158 32.8729 15.544H32.5754V26.2537H28.113V1.41309H34.7322C37.8559 1.41309 39.9384 3.19804 39.9384 7.7348C39.9384 11.6766 38.2278 13.3128 36.2941 13.5359Z" fill="#000"></path>
                      <path d="M47.5244 12.7178C47.7476 16.0646 47.7476 17.0314 47.7476 19.932C47.7476 21.8657 47.7476 26.2537 47.7476 26.2537H43.657V1.41309H47.7476C47.7476 1.41309 49.6813 8.70165 50.1275 10.1891C50.5737 11.751 50.8712 13.0897 51.0944 14.1309C50.9456 11.6766 50.9456 8.70165 50.9456 6.24734C50.9456 4.31364 50.9456 1.41309 50.9456 1.41309H54.8874V26.1793H51.2431C51.2431 26.1793 49.0119 18.5189 48.5657 17.0314C48.1194 15.9158 47.8219 14.5771 47.5244 12.7178Z" fill="#000"></path>
                      <path d="M63.1428 22.5351H68.4977V26.2537H58.7548V1.41309H67.8283V5.13174H63.1428V11.8997H67.3077V15.3209H63.1428V22.5351Z" fill="#000"></path>
                      <path d="M75.0425 20.527C75.0425 23.2788 75.6375 23.7251 76.6043 23.7251C77.3481 23.7251 77.943 23.2788 77.943 20.527C77.943 18.0727 76.3068 16.2878 74.4475 14.0566C72.7369 12.0485 70.8776 9.29668 70.8776 6.91674C70.8776 3.19808 72.5882 1.04126 76.0837 1.04126C79.5049 1.04126 81.3642 2.97496 81.3642 6.61924C81.3642 7.51172 81.2155 8.10671 81.0667 8.62732H77.3481C77.4224 8.25545 77.4224 7.66047 77.4224 6.91674C77.4224 4.5368 77.1993 3.86744 76.1581 3.86744C75.1912 3.86744 75.0425 4.83429 75.0425 6.54487C75.0425 8.77607 76.8274 10.561 78.2405 12.4204C80.323 15.0978 82.0336 17.2546 82.0336 19.7833C82.0336 23.7994 80.323 26.7744 76.53 26.7744C73.1088 26.7744 70.952 24.915 70.952 20.6014C70.952 19.6345 70.952 18.8164 71.2495 17.924H75.1912C75.1169 18.8908 75.0425 19.4114 75.0425 20.527Z" fill="#000"></path>
                      <path d="M112.601 12.7178C112.824 16.0646 112.824 17.0314 112.824 19.932C112.824 21.8657 112.824 26.2537 112.824 26.2537H108.734V1.41309H112.824C112.824 1.41309 114.758 8.70165 115.204 10.1891C115.65 11.751 115.948 13.0897 116.171 14.1309C116.022 11.6766 116.022 8.70165 116.022 6.24734C116.022 4.31364 116.022 1.41309 116.022 1.41309H119.964V26.1793H116.32C116.32 26.1793 114.088 18.5189 113.642 17.0314C113.196 15.9158 112.898 14.5771 112.601 12.7178Z" fill="#000"></path>
                      <path d="M130.227 13.8334C130.227 9.96604 130.227 7.51172 130.079 5.80114C129.93 4.01619 129.632 3.64432 129.037 3.64432C128.294 3.64432 128.071 4.01619 127.847 5.80114C127.699 7.51172 127.773 9.96604 127.773 13.8334C127.773 18.3702 127.773 20.0808 127.847 21.8657C127.996 23.7994 128.294 23.9482 129.037 23.9482C129.707 23.9482 129.93 23.7994 130.079 21.8657C130.227 20.0808 130.227 18.3702 130.227 13.8334ZM134.838 13.8334C134.838 24.5432 133.128 26.7 129.037 26.7C125.244 26.7 123.236 25.0638 123.236 13.8334C123.236 2.97496 124.798 1.04126 129.037 1.04126C133.277 1.04126 134.838 2.97496 134.838 13.8334Z" fill="#000"></path>
                      <path d="M142.499 4.31364V11.4535H143.615C144.804 11.4535 145.028 10.4122 145.028 8.25542C145.028 5.35486 144.73 4.31364 143.317 4.31364H142.499ZM142.499 14.5771V23.3532H143.615C144.953 23.3532 145.325 22.5351 145.325 18.742C145.325 15.9902 145.028 14.5771 143.615 14.5771H142.499ZM149.936 19.1883C149.936 24.32 147.631 26.1793 143.763 26.1793H138.185V1.41309H143.763C148.077 1.41309 149.193 4.01615 149.193 8.25542C149.193 11.3047 148.077 12.9409 146.143 13.0153C148.226 13.0153 149.936 15.1721 149.936 19.1883Z" fill="#000"></path>
                      <path d="M157.597 22.5351H162.803V26.2537H153.06V1.41309H157.597V22.5351Z" fill="#000"></path>
                      <path d="M169.571 22.5351H175V26.2537H165.257V1.41309H174.331V5.13174H169.571V11.8997H173.736V15.3209H169.571V22.5351Z" fill="#000"></path>
                      <path d="M17.9239 19.4858H20.1551C20.1551 19.4858 19.7833 16.5853 19.6345 14.7259C19.4858 13.536 19.0395 9.96606 18.8908 7.58612C18.8164 10.0404 18.4446 13.6103 18.3702 14.7259C18.2214 16.6596 17.9239 19.4858 17.9239 19.4858ZM13.3872 26.2538L17.0315 1.2644H21.2707L25.51 26.2538H20.8989L20.4526 22.2376H17.7008L17.2546 26.2538H13.3872Z" fill="#000"></path>
                      <path d="M23.2788 38.4511H24.1713C25.3612 38.4511 25.8819 37.7073 25.8819 36.8148C25.8819 35.9224 25.3612 35.1786 24.1713 35.1786H23.2788V38.4511ZM26.5512 41.0541C26.5512 40.0129 25.8819 39.1204 24.6175 39.1204H23.2788V41.2772C23.2788 42.7647 23.5019 42.9878 24.7663 42.9878H24.6919C25.9562 42.9878 26.5512 42.1697 26.5512 41.0541ZM28.6337 41.1285C28.6337 42.5416 27.4437 43.7315 25.51 43.7315H19.8576V43.2853C21.122 43.2853 21.3451 42.9878 21.3451 41.5004V36.7405C21.3451 35.1786 21.1964 34.9555 19.8576 34.9555V34.5093H25.0638C26.7743 34.5093 27.8156 35.5505 27.8156 36.8148C27.8156 37.6329 27.3693 38.3023 26.7 38.7485C27.9643 39.046 28.6337 40.0129 28.6337 41.1285Z" fill="#000"></path>
                      <path d="M36.8891 34.9553C34.7322 34.9553 33.8398 37.2609 33.8398 39.1202C33.8398 40.9795 34.7322 43.2851 36.8891 43.2851C39.0459 43.2851 39.9384 40.9795 39.9384 39.1202C39.9384 37.1865 39.0459 34.9553 36.8891 34.9553ZM36.8891 43.9545C34.0629 43.9545 31.7573 41.7233 31.7573 39.0458C31.7573 36.3684 34.0629 34.1372 36.8891 34.1372C39.7152 34.1372 42.0208 36.3684 42.0208 39.0458C42.0208 41.7233 39.7152 43.9545 36.8891 43.9545Z" fill="#000"></path>
                      <path d="M50.7225 34.9553C48.4913 34.9553 47.5988 37.1865 47.5988 39.0458C47.5988 40.9052 48.4913 43.2107 50.6481 43.2107C52.8049 43.2107 53.6974 40.9052 53.6974 39.0458C53.7718 37.1865 52.8793 34.9553 50.7225 34.9553ZM50.7225 43.9545C47.8963 43.9545 45.5907 41.7233 45.5907 39.0458C45.5907 36.3684 47.8963 34.1372 50.7225 34.1372C53.5487 34.1372 55.8542 36.3684 55.8542 39.0458C55.8542 41.7233 53.5487 43.9545 50.7225 43.9545Z" fill="#000"></path>
                      <path d="M68.7208 42.3184C69.2414 42.839 70.2826 43.2108 70.7289 43.2108V43.6571H65.5971V43.2108C66.4152 43.2108 66.7871 42.9134 66.1921 42.3184L63.1428 39.0459L62.4734 39.5666V41.4259C62.4734 42.9877 62.6966 43.2108 63.9609 43.2108V43.6571H59.1266V43.2108C60.391 43.2108 60.6141 42.9134 60.6141 41.4259V36.666C60.6141 35.1042 60.4654 34.8811 59.1266 34.8811V34.4348H63.9609V34.8811C62.6966 34.8811 62.4734 35.1785 62.4734 36.666V38.7485L65.7459 36.1454C66.1177 35.8479 67.0846 34.8811 65.8202 34.8811V34.4348H69.5389V34.8811C68.2745 34.8811 67.6796 35.3273 66.7127 36.1454L64.4815 37.9304L68.7208 42.3184Z" fill="#000"></path>
                      <path d="M74.6706 43.9545H74.2244V40.5334H74.6706C74.9681 42.1696 76.2325 43.2108 77.4968 43.2108C78.3893 43.2108 79.0586 42.6902 79.0586 41.649C79.0586 41.1283 78.6124 40.459 77.943 40.0871L76.4556 39.3434C75.1912 38.674 74.3731 37.9303 74.3731 36.4428C74.3731 35.1785 75.5631 34.1373 77.0506 34.1373C77.943 34.1373 78.4637 34.4348 78.9099 34.7323C79.0586 34.5835 79.2818 34.3604 79.3561 33.9885H79.8024V37.4097H79.3561C79.2818 36.1453 78.7611 34.8066 77.1249 34.8066C76.53 34.8066 75.8606 35.2529 75.8606 36.071C75.8606 36.5172 76.2325 37.2609 77.1249 37.6328L78.6124 38.3765C79.728 38.9715 80.6205 39.864 80.6205 41.1283C80.6205 42.8389 79.133 43.9545 77.5712 43.9545C76.3812 43.9545 75.6375 43.3595 75.1912 43.2108C74.8937 43.3595 74.745 43.5827 74.6706 43.9545Z" fill="#000"></path>
                      <path d="M84.7854 43.2853C86.0497 43.2853 86.2728 42.9878 86.2728 41.5004V36.7405C86.2728 35.1786 86.1241 34.9555 84.7854 34.9555V34.5093H92.7433L92.892 36.6661H92.4458L92.3714 36.3686C92.1483 35.5505 91.9996 35.1786 90.5865 35.1786H88.2065V38.5254H89.0246C90.5865 38.5254 90.8096 38.3023 90.8096 37.038H91.2558V40.6822H90.8096C90.8096 39.4179 90.5865 39.1948 89.0246 39.1948H88.2065V41.2772C88.2065 42.8391 88.4297 43.0622 89.694 43.0622H91.1071C92.5202 43.0622 92.6689 42.6903 92.892 41.8722L92.9664 41.5747H93.4127L93.2639 43.7315H84.7854V43.2853Z" fill="#000"></path>
                      <path d="M97.7263 34.8811V34.4348H102.561V34.8811C101.296 34.8811 101.073 35.1785 101.073 36.666V41.2028C101.073 42.7646 101.296 42.9877 102.561 42.9877H104.346C105.759 42.9877 105.907 42.6159 106.13 41.7978L106.205 41.5003H106.651L106.502 43.6571H97.6519V43.2108C98.9163 43.2108 99.1394 42.9134 99.1394 41.4259V36.666C99.2138 35.1785 98.9907 34.8811 97.7263 34.8811Z" fill="#000"></path>
                      <path d="M110.593 34.8811V34.4348H115.427V34.8811C114.163 34.8811 113.94 35.1785 113.94 36.666V41.2028C113.94 42.7646 114.163 42.9877 115.427 42.9877H117.212C118.625 42.9877 118.774 42.6159 118.997 41.7978L119.071 41.5003H119.518L119.369 43.6571H110.518V43.2108C111.783 43.2108 112.006 42.9134 112.006 41.4259V36.666C112.006 35.1785 111.857 34.8811 110.593 34.8811Z" fill="#000"></path>
                      <path d="M123.385 43.2853C124.649 43.2853 124.872 42.9878 124.872 41.5004V36.7405C124.872 35.1786 124.724 34.9555 123.385 34.9555V34.5093H131.343L131.492 36.6661H131.045L130.971 36.3686C130.748 35.5505 130.599 35.1786 129.186 35.1786H126.806V38.5254H127.624C129.186 38.5254 129.409 38.3023 129.409 37.038H129.855V40.6822H129.409C129.409 39.4179 129.186 39.1948 127.624 39.1948H126.806V41.2772C126.806 42.8391 127.029 43.0622 128.294 43.0622H129.707C131.12 43.0622 131.269 42.6903 131.492 41.8722L131.566 41.5747H132.012L131.864 43.7315H123.385V43.2853Z" fill="#000"></path>
                      <path d="M142.573 37.038C142.573 35.9967 141.978 35.1043 140.565 35.1043H139.747V38.8973H140.565C141.978 38.9717 142.573 38.0792 142.573 37.038ZM143.391 41.2772C144.73 42.7647 145.325 43.1366 146.292 43.2853V43.7315H142.945L140.193 39.7154H139.747V41.5004C139.747 43.0622 139.97 43.2853 141.235 43.2853V43.7315H136.4V43.2853C137.665 43.2853 137.888 42.9878 137.888 41.5004V36.7405C137.888 35.1786 137.739 34.9555 136.4 34.9555V34.5093H141.532C143.466 34.5093 144.656 35.6992 144.656 37.1123C144.656 38.4511 143.615 39.5667 141.904 39.7154L143.391 41.2772Z" fill="#000"></path>
                      <path d="M150.531 43.9545H150.085V40.5334H150.531C150.829 42.1696 152.093 43.2108 153.357 43.2108C154.25 43.2108 154.919 42.6902 154.919 41.649C154.919 41.1283 154.473 40.459 153.804 40.0871L152.316 39.3434C151.052 38.674 150.234 37.9303 150.234 36.4428C150.234 35.1785 151.424 34.1373 152.911 34.1373C153.804 34.1373 154.324 34.4348 154.771 34.7323C154.919 34.5835 155.142 34.3604 155.217 33.9885H155.663V37.4097H155.217C155.142 36.1453 154.622 34.8066 152.986 34.8066C152.391 34.8066 151.721 35.2529 151.721 36.071C151.721 36.5172 152.093 37.2609 152.986 37.6328L154.473 38.3765C155.589 38.9715 156.481 39.864 156.481 41.1283C156.481 42.8389 154.994 43.9545 153.432 43.9545C152.242 43.9545 151.498 43.3595 151.052 43.2108C150.754 43.3595 150.606 43.5827 150.531 43.9545Z" fill="#000"></path>
                      <path d="M91.7021 25.5101C88.2809 25.5101 87.0166 22.6095 87.3884 20.3783C87.8347 17.7009 89.099 15.9903 90.8096 14.5029C91.9996 17.4034 93.5614 20.4527 95.7926 23.5764C94.6026 24.9151 93.487 25.5101 91.7021 25.5101ZM92.3714 5.5037C92.6689 3.71875 94.1564 1.85942 96.462 1.85942C98.2469 1.85942 99.4369 3.19813 99.065 5.35496C98.9163 6.17306 98.6932 6.91679 97.875 7.88364C97.0569 8.92487 94.1564 10.7098 92.7433 11.4536C92.1483 9.37111 91.9996 7.51178 92.3714 5.5037ZM103.453 13.3129C101.742 13.3129 100.478 14.5029 99.66 16.0647C98.9163 17.8496 98.0238 19.8577 97.5032 20.9733C95.5695 17.7009 94.1564 15.0978 93.2639 12.7923C94.7514 11.9742 97.3544 10.7098 98.7675 9.37111C100.255 8.03239 100.924 6.76805 101.222 5.28058C101.668 2.52878 100.106 0.0744629 96.6107 0.0744629C93.6358 0.0744629 90.1402 2.38003 89.4709 6.24743C89.099 8.25551 89.5453 10.7842 90.289 12.8666C87.6116 14.6516 85.306 16.6597 84.711 19.7833C83.8185 24.9151 87.0909 27.295 90.7352 27.295C93.1895 27.295 95.1232 26.1794 96.6107 24.4689C97.7263 25.8076 99.2138 27.295 100.999 27.295C102.486 27.295 103.974 26.3282 105.164 24.8407L104.643 24.097C103.527 25.1382 102.709 25.5845 102.04 25.5845C100.85 25.5845 99.66 24.5432 98.6188 22.907C98.47 22.6839 98.3213 22.3864 98.1725 22.1633C98.8419 20.8246 99.3625 19.4115 99.9575 18.0728C101.073 15.6184 101.742 14.8003 102.784 14.8003C104.494 14.8003 103.156 17.7009 104.94 17.7009C105.833 17.7009 106.205 16.9572 106.354 16.2878C106.577 15.0978 105.907 13.3129 103.453 13.3129Z" fill="#000"></path>
                      <path d="M91.5533 4.61108C91.5533 4.61108 89.1734 8.18099 92.5202 15.4696C95.1976 21.4194 98.47 24.7662 98.47 24.7662C98.47 24.7662 95.1976 20.3038 92.9664 14.949C90.2146 8.47849 91.5533 4.61108 91.5533 4.61108Z" fill="#000"></path>
                    </svg>
                  </div>
                </a>
                <span>&nbsp;</span>
                <a className="orderLink svg-shorter" href="https://www.christianbook.com/heart-breaking-finding-gods-presence-pain/ron-hutchcraft/9780736981415/pd/981415?event=ESRCG" target="__blank">
                  <div className="img-fluid">
                    <svg width="120" height="89" viewBox="0 0 120 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.83392 67.8091C8.28622 67.1554 7.49116 66.7844 6.46643 66.7844C4.39929 66.7844 2.86219 68.4805 2.86219 70.795C2.86219 73.1272 4.39929 74.8056 6.37809 74.8056C7.49116 74.8056 8.39222 74.3109 8.93993 73.4628L11.2367 75.1766C10.1943 76.6607 8.44523 77.3498 6.73145 77.3498C2.84452 77.3498 0 74.8056 0 70.795C0 66.8021 2.82685 64.2402 6.73145 64.2402C8.12721 64.2402 9.87632 64.7173 10.9364 66.0777" fill="#000"></path>
                      <path d="M14.9647 63.7102V69.6289H15C15.2473 68.9576 16.1131 68.2508 17.4028 68.2508C20.0707 68.2508 20.5654 70.0176 20.5654 72.2968V77.0141H17.9152V72.8445C17.9152 71.8551 17.8975 70.583 16.5371 70.583C15.159 70.583 14.947 71.6607 14.947 72.7738V77.0318H12.3322V63.7279" fill="#000"></path>
                      <path d="M22.3675 68.4806H25V69.8587H25.0353C25.6007 68.8163 26.3428 68.2686 27.4912 68.2686C27.7915 68.2686 28.1272 68.2862 28.3746 68.3569V70.7774C28.0035 70.6714 27.6325 70.6007 27.2438 70.6007C25.2297 70.6007 24.9823 71.7668 24.9823 73.4629V77.0318H22.3498" fill="#000"></path>
                      <path d="M30.8127 64.2402C31.6608 64.2402 32.3498 64.9293 32.3498 65.7773C32.3498 66.6254 31.6608 67.3144 30.8127 67.3144C29.9646 67.3144 29.2756 66.6254 29.2756 65.7773C29.2933 64.9293 29.9646 64.2402 30.8127 64.2402ZM29.5053 68.4805H32.1555V77.0317H29.5053V68.4805Z" fill="#000"></path>
                      <path d="M38.8162 70.9892C38.4276 70.5122 37.9152 70.2649 37.2791 70.2649C36.8374 70.2649 36.3074 70.4592 36.3074 70.9716C36.3074 72.2437 40.7597 71.1659 40.7597 74.4168C40.7597 76.6076 38.6396 77.226 36.8198 77.226C35.6184 77.226 34.3109 76.9257 33.4629 76.0599L35.0707 74.2755C35.5654 74.8762 36.1484 75.2119 36.9435 75.2119C37.5442 75.2119 38.1449 75.0529 38.1449 74.5935C38.1449 73.2154 33.6926 74.3815 33.6926 71.1306C33.6926 69.1165 35.4947 68.2507 37.2791 68.2507C38.4099 68.2507 39.6643 68.5334 40.4417 69.3815" fill="#000"></path>
                      <path d="M47.8799 70.5832H45.5477V73.4277C45.5477 74.3464 45.6537 75.0178 46.8021 75.0178C47.0848 75.0178 47.6502 74.9825 47.8799 74.7705V76.9789C47.2968 77.1909 46.6431 77.2439 46.0247 77.2439C44.258 77.2439 42.9152 76.5196 42.9152 74.6644V70.6008H41.2191V68.4807H42.9152V65.9365H45.5654V68.4807H47.8975" fill="#000"></path>
                      <path d="M50.4947 64.2402C51.3427 64.2402 52.0318 64.9293 52.0318 65.7773C52.0318 66.6254 51.3427 67.3144 50.4947 67.3144C49.6466 67.3144 48.9576 66.6254 48.9576 65.7773C48.9752 64.9293 49.6643 64.2402 50.4947 64.2402ZM49.1873 68.4805H51.8374V77.0317H49.1873V68.4805Z" fill="#000"></path>
                      <path d="M59.0283 75.9539H58.9929C58.4099 76.8726 57.3498 77.2437 56.2721 77.2437C54.6643 77.2437 53.2332 76.3249 53.2332 74.5935C53.2332 71.643 56.8198 71.59 59.0283 71.59C59.0283 70.6536 58.2509 70.0529 57.3322 70.0529C56.4664 70.0529 55.742 70.4415 55.1767 71.0776L53.7633 69.6288C54.735 68.7101 56.1484 68.2507 57.5265 68.2507C60.6007 68.2507 61.4311 69.8232 61.4311 72.6677V77.014H58.9929L59.0283 75.9539ZM58.3746 73.2861C57.5618 73.2861 55.7774 73.3567 55.7774 74.5228C55.7774 75.1589 56.4488 75.4592 57.0318 75.4592C58.0742 75.4592 59.0459 74.9115 59.0459 73.8514V73.2861H58.3746Z" fill="#000"></path>
                      <path d="M63.0389 68.4806H65.5654V69.6466H65.6007C65.9541 68.9753 66.8198 68.2686 68.0919 68.2686C70.7774 68.2686 71.2544 70.0353 71.2544 72.3145V77.0318H68.6042V72.8445C68.6042 71.8551 68.5866 70.583 67.2262 70.583C65.8481 70.583 65.636 71.6608 65.636 72.7739V77.0318H62.9859" fill="#000"></path>
                      <path d="M73.3215 63.7102H75.954V69.3816H75.9894C76.5901 68.5512 77.6148 68.2508 78.6926 68.2508C81.1307 68.2508 82.5971 70.424 82.5971 72.8092C82.5971 75.3887 80.9187 77.2261 78.3922 77.2261C77.1908 77.2261 76.1661 76.5017 75.7774 75.8834H75.742V77.0141H73.3039L73.3215 63.7102ZM77.8622 74.9116C79.1873 74.9116 79.9823 74.0282 79.9823 72.7385C79.9823 71.4487 79.1873 70.5653 77.8622 70.5653C76.5371 70.5653 75.742 71.4487 75.742 72.7385C75.742 74.0282 76.5371 74.9116 77.8622 74.9116Z" fill="#000"></path>
                      <path d="M88.5336 68.2686C91.1837 68.2686 93.2862 70.0353 93.2862 72.7562C93.2862 75.477 91.1661 77.2438 88.5336 77.2438C85.8834 77.2438 83.7809 75.477 83.7809 72.7562C83.7809 70.0177 85.8834 68.2686 88.5336 68.2686ZM88.5336 74.9117C89.8587 74.9117 90.6537 74.0283 90.6537 72.7385C90.6537 71.4488 89.8587 70.5654 88.5336 70.5654C87.2085 70.5654 86.4134 71.4488 86.4134 72.7385C86.4134 74.0283 87.2085 74.9117 88.5336 74.9117Z" fill="#000"></path>
                      <path d="M99.205 68.2686C101.855 68.2686 103.958 70.0353 103.958 72.7562C103.958 75.477 101.837 77.2438 99.205 77.2438C96.5548 77.2438 94.4523 75.477 94.4523 72.7562C94.4523 70.0177 96.5548 68.2686 99.205 68.2686ZM99.205 74.9117C100.53 74.9117 101.325 74.0283 101.325 72.7385C101.325 71.4488 100.53 70.5654 99.205 70.5654C97.8799 70.5654 97.0848 71.4488 97.0848 72.7385C97.0848 74.0283 97.8799 74.9117 99.205 74.9117Z" fill="#000"></path>
                      <path d="M105.424 63.7102H108.074V71.8551L110.989 68.4805H114.24L110.83 72.3321L114.329 77.0318H110.989L108.11 72.7031H108.074V77.0318H105.424" fill="#000"></path>
                      <path d="M17.4558 81.1309H20.2297C22.0848 81.1309 23.8163 82.2616 23.8163 84.6468C23.8163 87.0496 21.7845 88.1627 20.0883 88.1627H17.4558V81.1309ZM19.6467 87.0496C21.2191 87.0496 22.5088 86.3782 22.5088 84.6644C22.5088 82.9506 21.3958 82.2793 19.8587 82.2793H18.7103V87.0496H19.6467Z" fill="#000"></path>
                      <path d="M27.2262 81.1309H25.9717V88.1803H27.2262V81.1309Z" fill="#000"></path>
                      <path d="M33.2155 82.6501C32.9505 82.2791 32.4735 82.0848 31.9788 82.0848C31.3957 82.0848 30.8304 82.3498 30.8304 82.9858C30.8304 84.3993 34.1696 83.5865 34.1696 86.0954C34.1696 87.6148 32.9682 88.3392 31.5901 88.3392C30.7067 88.3392 29.8586 88.0742 29.2756 87.3851L30.2297 86.4664C30.53 86.9258 31.0601 87.2085 31.6254 87.2085C32.2085 87.2085 32.8622 86.8904 32.8622 86.2367C32.8622 84.6996 29.523 85.583 29.523 83.0565C29.523 81.6077 30.8127 80.9187 32.1201 80.9187C32.8622 80.9187 33.5866 81.1307 34.1343 81.6431" fill="#000"></path>
                      <path d="M37.9152 82.2616H35.7597V81.1309H41.3251V82.2616H39.1696V88.1803H37.9152" fill="#000"></path>
                      <path d="M43.1802 81.1309H45.636C46.9965 81.1309 48.2685 81.5726 48.2685 83.145C48.2685 84.1521 47.6855 84.9118 46.6431 85.0531L48.5159 88.1627H46.9965L45.371 85.1768H44.4523V88.1627H43.1979L43.1802 81.1309ZM45.4064 84.1167C46.1131 84.1167 46.9435 84.0637 46.9435 83.145C46.9435 82.3146 46.1661 82.2086 45.5124 82.2086H44.4346V84.1167H45.4064Z" fill="#000"></path>
                      <path d="M51.6785 81.1309H50.424V88.1803H51.6785V81.1309Z" fill="#000"></path>
                      <path d="M54.2049 81.1309H56.9435C58.0035 81.1309 59.0989 81.6256 59.0989 82.8976C59.0989 83.6927 58.6042 84.2227 57.8799 84.4347V84.4524C58.7633 84.5584 59.3993 85.2475 59.3993 86.1485C59.3993 87.6856 58.0742 88.1627 56.7315 88.1627H54.1873L54.2049 81.1309ZM55.4594 83.993H56.6078C57.4382 83.993 57.8622 83.6397 57.8622 83.1097C57.8622 82.5089 57.4382 82.2086 56.5018 82.2086H55.4594V83.993ZM55.4594 87.1026H56.6078C57.2615 87.1026 58.1449 86.9966 58.1449 86.0602C58.1449 85.3181 57.6678 85.0708 56.6608 85.0708H55.4594V87.1026Z" fill="#000"></path>
                      <path d="M67.2968 85.6007C67.2968 87.2969 66.0777 88.3746 64.4523 88.3746C62.8445 88.3746 61.6078 87.3145 61.6078 85.6007V81.1484H62.8622V85.5654C62.8622 86.2544 63.2332 87.1732 64.4523 87.1732C65.6537 87.1732 66.0424 86.2544 66.0424 85.5654V81.1484H67.2968" fill="#000"></path>
                      <path d="M71.2721 82.2616H69.1166V81.1309H74.682V82.2616H72.5265V88.1803H71.2721" fill="#000"></path>
                      <path d="M79.8763 80.954C82.0671 80.9187 83.6219 82.3851 83.6219 84.6466C83.6219 86.8374 82.0671 88.3215 79.8763 88.3568C77.7032 88.3568 76.1484 86.8904 76.1484 84.6819C76.1661 82.4204 77.7208 80.954 79.8763 80.954ZM79.894 87.2261C81.3604 87.2261 82.3145 86.113 82.3145 84.6112C82.3145 83.2155 81.3427 82.0847 79.894 82.0847C78.4452 82.0847 77.4735 83.1978 77.4735 84.6112C77.4735 86.113 78.4452 87.2261 79.894 87.2261Z" fill="#000"></path>
                      <path d="M85.8304 81.1309H88.2862C89.6466 81.1309 90.9187 81.5726 90.9187 83.145C90.9187 84.1521 90.3357 84.9118 89.2933 85.0531L91.1661 88.1627H89.6466L88.0212 85.1768H87.1025V88.1627H85.8481L85.8304 81.1309ZM88.0565 84.1167C88.7633 84.1167 89.5936 84.0637 89.5936 83.145C89.5936 82.3146 88.8163 82.2086 88.1625 82.2086H87.0848V84.1167H88.0565Z" fill="#000"></path>
                      <path d="M96.5371 82.6501C96.2721 82.2791 95.7951 82.0848 95.3004 82.0848C94.7173 82.0848 94.1519 82.3498 94.1519 82.9858C94.1519 84.3993 97.4912 83.5865 97.4912 86.0954C97.4912 87.6148 96.2897 88.3392 94.9117 88.3392C94.0283 88.3392 93.1802 88.0742 92.5972 87.3851L93.5512 86.4664C93.8516 86.9258 94.3816 87.2085 94.947 87.2085C95.53 87.2085 96.1837 86.8904 96.1837 86.2367C96.1837 84.6996 92.8445 85.583 92.8445 83.0565C92.8445 81.6077 94.1343 80.9187 95.4417 80.9187C96.1837 80.9187 96.9081 81.1307 97.4558 81.6431" fill="#000"></path>
                      <path d="M109.276 32.9859C111.449 37.7562 112.792 41.9965 113.587 47.3145C95.795 49.364 90.742 33.7986 76.4311 32.9505C66.4664 32.8622 61.1484 40.9541 57.7032 47.1025C54.523 37.1025 46.3251 8.49823 20.2473 16.4841C18.9046 16.9435 17.1025 17.6855 15.8481 18.2686C16.3604 17.6148 16.0601 18.0212 17.7385 16.1131C30.9187 3.55124 47.3145 -1.89046 65.9894 0.583039C82.9859 2.9682 99.2579 13.9753 107.544 29.4523" fill="#000"></path>
                      <path d="M27.7032 34.9293C36.8905 31.3251 42.7385 32.4205 48.5159 37.0671C51.5018 39.417 54.3993 43.3039 57.6679 49.9117C64.8587 39.3816 74.3816 38.5336 77.9505 39.0459C86.2014 40.1767 91.3958 44.6466 98.8339 47.8269C102.968 49.3816 109.24 50.212 114.011 49.3816C114.117 50.0707 114.187 51.2544 114.187 51.2544C111.908 51.4311 110.3 51.5901 107.12 51.4841C93.2686 50.53 85.8127 41.3074 73.0036 42.4205C64.9823 43.4276 59.735 48.9929 57.5795 53.2332C54.5583 46.7668 49.6643 41.8728 46.9258 40.0707C44.4523 38.4276 40.6007 37.0671 35.9541 37.4558C31.9788 37.8799 28.3922 39.2226 24.4876 40.1943C17.1732 41.7138 11.8905 41.7138 4.13428 37.0495C6.55477 30.8127 9.85866 24.6997 14.7173 19.47L15.4594 20.0883" fill="#000"></path>
                      <path d="M114.452 53.2333C95.159 54.9294 87.5265 45.7068 72.7562 46.9259C66.7315 47.4206 60.3534 51.4135 57.5972 55.2828C54.629 51.0602 51.7491 47.4559 45.7951 44.9118C38.3922 42.0672 34.9293 42.8976 14.3286 50.1061L4.43464 41.4842L2.98587 40.3181C1.07775 45.8835 0.441704 54.5407 0.459372 54.7704L1.80213 54.8764C19.417 55.1591 26.4841 47.0849 38.7986 46.8376C49.4346 46.6255 55.424 55.4418 56.3604 56.1662C57.3322 56.9259 58.1449 56.8376 58.8163 56.3429C59.47 55.8482 65.9894 49.3641 75.9541 49.5054C89.2226 49.6821 91.9788 55.8658 114.558 54.9648C114.523 54.4877 114.452 53.2333 114.452 53.2333Z" fill="#000"></path>
                      <path d="M0.441696 60.901V57.2615H54.0459C55.265 58.6749 57.2261 59.2226 59.0283 58.7279C59.9117 58.5159 60.6537 57.9329 61.3251 57.3498L114.488 57.2791V60.8834" fill="#000"></path>
                      <path d="M115.883 58.9576C115.883 57.7915 116.837 56.9258 117.951 56.9258C119.064 56.9258 120 57.7915 120 58.9576C120 60.1413 119.064 60.9894 117.951 60.9894C116.82 60.9894 115.883 60.1413 115.883 58.9576ZM117.951 60.6537C118.869 60.6537 119.594 59.9293 119.594 58.9576C119.594 58.0035 118.869 57.2791 117.951 57.2791C117.014 57.2791 116.29 58.0035 116.29 58.9576C116.29 59.9293 117.014 60.6537 117.951 60.6537ZM117.509 60.1413H117.155V57.7915H118.039C118.587 57.7915 118.869 58.0035 118.869 58.4629C118.869 58.8869 118.604 59.0636 118.269 59.1166L118.94 60.1413H118.534L117.915 59.1343H117.509V60.1413ZM117.933 58.8162C118.233 58.8162 118.498 58.7986 118.498 58.4275C118.498 58.1449 118.233 58.0919 117.986 58.0919H117.509V58.8162H117.933Z" fill="#000"></path>
                    </svg>
                  </div>
                </a>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col className="mt-5 pt-4 pb-3" xs={12} md={8}>
                <a className="orderLink hopeDonate" href={"https://donate.hutchcraft.com/campaign/hope-when-your-heart-is-breaking/c313058" + this.props.donateTrackingParams} target="__blank">
                  <div className="img-fluid">
                    <img src={hopeDonate} alt="Donate to Ron Hutchcraft Ministries" />
                  </div>
                </a>
              </Col>
            </Row>            
          </Container>
      </section>
    );
  }
}

Order.propTypes = {
  donateTrackingParams: PropTypes.string
}