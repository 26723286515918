import React from "react"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import VimeoEmbed from "./VimeoEmbed"

export default class Trailer extends React.Component {
  render() {
    return (
      <section id="trailer">
          <Container>
            <Row>
              <Col>
                <h1>ABOUT THE BOOK</h1>
                <div className="trailerEmbed"><VimeoEmbed title="Hope When Your Heart Is Breaking" vimeoId="463200335"></VimeoEmbed></div>
                <p>Watch Ron Hutchcraft share about his new book, Hope When Your Heart Is Breaking.</p>
              </Col>
            </Row>
          </Container>
      </section>
    );
  }
}
