import React from "react"
import About from "../components/About"
import Cover from "../components/Cover"
import Endorsements from "../components/Endorsements"
import Footer from "../components/Footer"
import Layout from "../components/Layout"
import More from "../components/More"
import News from "../components/News"
import Order from "../components/Order"
import PreCover from "../components/PreCover"
import Sample from "../components/Sample"
import SEO from "../components/Seo"
import Trailer from "../components/Trailer"

export default class Home extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      cache: { },
      donateTrackingParams: ''
    }
  }

  componentDidMount() {
    let src = this.getParameterByName('src');
    if(typeof window !== 'undefined') {
      if(src && src.toLowerCase() === 'youversion') {
        window.piAId = '837243';
        window.piCId = '68348';  
      } else {
        window.piAId = '837243';
        window.piCId = '2541';
      }
      window.piHostname = 'info.hopewhenyourheartisbreaking.com';
      this.loadScript('pi', 'https://info.hopewhenyourheartisbreaking.com/pd.js').then(() => { 
        if(window.piCId === '68348') {
          this.setState({
            donateTrackingParams: '?c_src=YouVersion'
          });  
        }
      }).catch(() => { return; });
    }
  }

  getParameterByName(name) {
    if(typeof window !== 'undefined') {
      let url = window.location.href;
      name = name.replace(/[[\]]/g, "\\$&");
      let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    } else {
      return null;
    }
  }

  loadScript(id, url, extraAttrs) {
    if(typeof this.state.cache[id] === 'undefined' && typeof document !== 'undefined') {
      this.state.cache[id] = new Promise(function(resolve, reject) {
        let head = document.getElementsByTagName("head")[0] || document.documentElement;      
        let done = false;
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        if(extraAttrs) {
          for(var prop in extraAttrs){ 
            script[prop] = extraAttrs[prop];
          }
        }

        script.onload = script.onreadystatechange = function() {
          if (!done && (!script.readyState || script.readyState === "loaded" || script.readyState === "complete")) {
            done = true;

            // Handle memory leak in IE
            script.onload = script.onreadystatechange = null;
            resolve(script);
          }
        };

        script.onerror = reject;

        head.appendChild(script);
      });
    }

    return this.state.cache[id];
  }

  render() {
    return ( 
      <Layout>
        <SEO />
        <PreCover />
        <Cover />
        <Trailer />
        <Sample />
        <Endorsements />
        <About />
        <News />
        <Order donateTrackingParams={this.state.donateTrackingParams} />
        <More />
        <Footer />
      </Layout>
    );
  }
}