import React from "react"
import logo from 'assets/img/rhm-logo-white.png'
import Container from "react-bootstrap/Container"
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Scroller from "./scroller";

export default class Header extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
  }

  render() {
    return (
      <header>
        <Container>
          <Navbar bg="transparent" variant="dark" expand="lg">
            <Navbar.Brand href="https://hutchcraft.com"><img className="logo" src={logo} alt="Ron Hutchcraft Ministries"/></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link href="#trailer" onClick={Scroller.handleAnchorScroll}>Trailer</Nav.Link>
                <Nav.Link href="#sample" onClick={Scroller.handleAnchorScroll}>Sample Chapter</Nav.Link>
                <Nav.Link href="#endorsements" onClick={Scroller.handleAnchorScroll}>Endorsements</Nav.Link>
                <Nav.Link href="#about" onClick={Scroller.handleAnchorScroll}>About the Author</Nav.Link>
                <Nav.Link href="#news" onClick={Scroller.handleAnchorScroll}>News</Nav.Link>
                <Nav.Link href="#order" onClick={Scroller.handleAnchorScroll}>Order</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </header>
    )
  }
}
