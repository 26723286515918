import React from "react"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";

export default class Sample extends React.Component {
  render() {
    return (
      <section id="sample">
          <Container>
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <h1>DISCOVER HOPE AND HEALING</h1>
                <p>At the crossroads of grief, one road will lead to hope and healing. The other, to more hurt.</p>
                <p><em>Hope When Your Heart Is Breaking</em> is an honest look at both roads, and <strong>how your greatest loss can lead to your greatest gain.</strong></p>
                <p>Author Ron Hutchcraft writes from the deep well of his own devastating loss and grief, and points you to the practical steps that lead to peace and wholeness.</p>
                <p>This book is a pathway to hope &ndash; a roadmap through the pain of grief and loss. Discover new strength through a new closeness to others and to God. And make the decisions that lead to comfort, growth, and life.</p>
                <div className="sampleChapter">
                  FREE Sample Chapter:<br/>
                  <a href="/Hope-When-Your-Heart-Is-Breaking-Excerpt.pdf" download="Hope-When-Your-Heart-Is_Breaking-Excerpt.pdf"><strong>Download Foreword, Intro, Chapter&nbsp;1</strong></a><br />
                  <Button className="px-4" href="/Hope-When-Your-Heart-Is-Breaking-Excerpt.pdf" download="Hope-When-Your-Heart-Is_Breaking-Excerpt.pdf">DOWNLOAD</Button>
                </div>
              </Col>
            </Row>
          </Container>
      </section>
    );
  }
}
