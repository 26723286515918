import React from "react"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Endorsement from "./Endorsement";
import Scroller from "./scroller";

export default class Endorsements extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMore: false
    }
    this.onShowMore = this.onShowMore.bind(this)
    this.onShowLess = this.onShowLess.bind(this)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
  }

  onShowMore = event => {
    if(event) {
      event.preventDefault();
    }
    this.setState({
      showMore: true
    });
  };

  onShowLess = event => {
    if(event) {
      event.preventDefault();
    }
    Scroller.handleAnchorScroll(event);
    this.setState({
      showMore: false
    });
  };

  render() {
    return (
      <section id="endorsements">
          <Container>
            <Row>
              <Col>
                <h1>ENDORSEMENTS</h1>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6}>
                <Endorsement imageUrl="/endorsements/Joni-Eareckson-Tada.jpg" name="Joni Eareckson Tada" bio="Joni and Friends International Disability Center">
                  <em>Hope When Your Heart Is Breaking</em> is your husky, bravehearted guide through dark valleys 
                  into the fresh air of biblical hope. Its pages may be splattered with pain, but you will find on 
                  them eternal principles that will be a light for your path.
                </Endorsement>
              </Col>
              <Col xs={12} lg={6}>
                <Endorsement imageUrl="/endorsements/Josh-McDowell.jpg" name="Josh D. McDowell" bio="author">
                  As a popular speaker and longtime radio host, Ron's voice and practical wisdom are known to many. But this is my friend 
                  Ron as you've never heard him&ndash;with a depth of compassion, honesty, and hope that can only come from a heart that 
                  has been broken&hellip;and then flooded with hope.
                </Endorsement>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6}>
                <Endorsement imageUrl="/endorsements/Jerry-Jenkins.jpg" name="Jerry B. Jenkins" bio="novelist and biographer, coauthor of the Left Behind series">
                  As has been his trademark for decades, Ron attacks the gritty reality of grief in a way that not only reveals his heart 
                  but also offers biblical insight and peace to anyone suffering heartache from any source. Ron unflinchingly casts himself 
                  into the embrace of the God of all comfort.
                </Endorsement>
              </Col>
              <Col xs={12} lg={6}>
                <Endorsement imageUrl="/endorsements/Dr-Erwin-Lutzer.jpg" name="Dr. Erwin W. Lutzer" bio="pastor emeritus, The Moody Church, Chicago">
                  This is a great book, readable, honest, and biblical. A healing balm for those who are suddenly thrust into a bleak future 
                  and left only with memories of happier times. Thanks, Ron, for this window into your life that gives us a ray of hope in our 
                  sorrow and the reminder that, despite our losses, we still have God and His promises. Read this book for yourself and pass 
                  it along to a friend.
                </Endorsement>
              </Col>
            </Row>

            {this.state.showMore===false && <Row>
              <Col className="text-center mt-3">
                <a href="#showMore" className="showMore" onClick={this.onShowMore}>SHOW MORE</a>
              </Col>
            </Row>}

            {this.state.showMore===true && <Row>
              <Col xs={12} lg={6}>
                <Endorsement imageUrl="/endorsements/Will-Graham.jpg" name="Will Graham" bio="executive director of the Billy Graham Training Center at The Cove">
                  As followers of Jesus, we have the blessing of knowing that this broken world isn't all there is. Through touching personal 
                  stories and his own poignant experiences of brokenness and loss, Ron repeatedly shares the source of our <em>defiant hope</em>: Jesus Christ. 
                  If you have experienced traumatic loss, I encourage you to read <em>Hope When Your Heart iIs Breaking</em> and cling to the biblical 
                  promises Ron offers.
                </Endorsement>
              </Col>
              <Col xs={12} lg={6}>
                <Endorsement imageUrl="/endorsements/Jim-Cymbala.jpg" name="Jim Cymbala" bio="pastor, The Brooklyn Tabernacle">
                  When Ron Hutchcraft writes a book, you can be sure it contains both biblical truth and spiritual inspiration. 
                  <em>Hope When Your Heart Is Breaking</em> will lift all our hearts during the difficult seasons of our lives.
                </Endorsement>
              </Col>
            </Row>}
            {this.state.showMore===true && <Row>
              <Col xs={12} lg={6}>
                <Endorsement imageUrl="/endorsements/Nick-Hall.jpg" name="Nick Hall" bio={<span>founder and chief communicator, <em>Pulse</em></span>}>
                  I know firsthand the pain of loss when plans change, relationships end, and loved ones die. 
                  In <em>Hope When Your Heart Is Breaking</em>, my friend Ron Hutchcraft reminds us through Scripture that God 
                  is still good. And God is still Hope. This is more than a book&ndash;it's a lifeline.
                </Endorsement>
              </Col>
              <Col xs={12} lg={6}>
                <Endorsement imageUrl="/endorsements/June-Hunt.png" name="June Hunt" bio="founder and chief servant officer, Hope For The Heart and The Hope Center; author">
                  We all have a need for hope. But where is hope when the unexpected happens? Our friend Ron writes about his 
                  heartbreak with unvarnished honesty&ndash;it's real, but so is his needed help, leading us hand in hand to 
                  the place of renewed hope. Yes, we all have a need for <em>this kind of hope!</em>
                </Endorsement>
              </Col>
            </Row>}
            {this.state.showMore===true && <Row>
              <Col xs={12} lg={6}>
                <Endorsement imageUrl="/endorsements/Ken-Davis.jpg" name="Ken Davis" bio="author, professional speaker, president of Dynamic Communicators">
                  All of us will experience the pain of a broken heart and glimpse the abyss of hopelessness more than once during our life. 
                  The wisdom Ron shares in this book reveals practical steps that lead from the valley of the shadow of death to the sun-drenched 
                  slopes of hope. I saw my own heartbreak in part two and my own life is brighter because of this book. Yours will be too.
                </Endorsement>
              </Col>
              <Col xs={12} lg={6}>
                <Endorsement imageUrl="/endorsements/Dr-Crawford.jpg" name="Dr. Crawford W. Loritts, Jr." bio="author, speaker, radio host, and senior pastor, Fellowship Bible Church">
                  Part of my calling as a pastor is to give hope to hurting people. But hope can be fragile, and holding on to it can be a 
                  real struggle. Ron Hutchcraft knows this battle well. Like so many of us, he also has fought to hold on to hope during dark, 
                  painful times. That's the reason <em>Hope When Your Heart Is Breaking</em> is such a blessing and a treasure. 
                  Thank you, Ron, for showing us the way to keep hope alive. What a gift!
                </Endorsement>
              </Col>
            </Row>}
            {this.state.showMore===true && <Row>
              <Col xs={12} lg={6}>
                <Endorsement imageUrl="/endorsements/Robert-Wolgemuth.jpg" name="Robert Wolgemuth" bio="bestselling author">
                  There are many takeaways in this book&hellip;but the following three are my favorites. They will prove helpful to everyone 
                  who embraces them: <em>I will not deny my pain; I will not be defined by my loss; I will rely on an unseen but certain Hope 
                  beyond the hurt.</em> Thank you, Ron. These are an enormous help.
                </Endorsement>
              </Col>
              <Col xs={12} lg={6}>
                <Endorsement imageUrl="/endorsements/Chris-Fabry.jpg" name="Chris Fabry" bio={<span>host of <em>Chris Fabry Live</em>, author of <em>A Piece of the Moon</em></span>}>
                  Ron Hutchcraft is a faithful steward of pain. This book is not simply good advice; it's life-tested, rock-solid truth you can rely on. 
                  There is no broken part of your life God does not see. Trust Him with your broken pieces. Reading Ron's book will provide hope and light 
                  in the darkness.
                </Endorsement>
              </Col>
            </Row>}
            {this.state.showMore===true && <Row>
              <Col xs={12} lg={6}>
                <Endorsement imageUrl="/endorsements/Huron-Claus.jpg" name="Huron Claus" bio="president, CHIEF Inc. (Christian Hope Indian Eskimo Fellowship)">
                  Has there ever been a time when you didn't have the answers to life's deep questions during a great loss in your life? 
                  How do you respond in times of uncertainty and overwhelming fear? What do you do when your whole world collapses around you 
                  and you feel so alone? Ron so tenderly and genuinely communicates a life lesson learned from the One who created him. Once 
                  you pick this book up, you will not want to put it down until you receive all from God's conversation with Ron.
                </Endorsement>
              </Col>
              <Col xs={12} lg={6}>
                <Endorsement imageUrl="/endorsements/Miriam-Neff.jpg" name="Miriam Neff" bio="founder and president of Widow Connection, author, counselor, and speaker">
                  Ron's words create poignant, transforming pictures in our minds, drawing us to a higher place. Better than cloud-chasing hope, 
                  Scripture's truths can be grasped, lifting us to that place. Not cotton candy&ndash;sweet for only a moment&ndash;but truths that 
                  provide strength through the valley. I've been through that valley myself. I commend Ron's words to you, because they are our 
                  Lord's words. Hope guaranteed.
                </Endorsement>
              </Col>
            </Row>}
            {this.state.showMore===true && <Row>
              <Col xs={12} lg={6}>
                <Endorsement imageUrl="/endorsements/George-Verwer.jpg" name="George Verwer, DD" bio="founder of Operation Mobilization">
                  I have had a half-century friendship with the man of God who wrote this greatly needed and relevant book. With suffering out of control 
                  in the world today, this message is not optional. If you are in a hurry, start with the last chapter&ndash;"The Only Safe Place."
                </Endorsement>
              </Col>
              <Col xs={12} lg={6}>
                <Endorsement imageUrl="/endorsements/Janet-Parshall.jpg" name="Janet Parshall" bio="nationally syndicated talk show host, author">
                  This side of glory, we will have pain, loss, and heartache. But we also have something else&ndash;hope. Ron reminds us of God's Word: 
                  Hope never fails. It's what gets you through the night, past the pain, and into the center of His love. Drink in these pages and be 
                  reminded of the priceless constancy of the gift of hope.
                </Endorsement>
              </Col>
            </Row>}
            {this.state.showMore===true && <Row>
              <Col xs={12} lg={6}>
                <Endorsement imageUrl="/endorsements/Joe-Battaglia.jpg" name="Joe Battaglia" bio={<span>broadcaster, author of <em>Make America Good Again</em>, president of Renaissance Communications</span>}>
                  Ron opens up his heart to honestly reveal both the pain and the peace, the grief and the goodness, and the emptiness and the fullness of 
                  Christ's presence in the middle of a storm. This book will be transformational to anyone searching for a heartfelt, honest approach to a sudden loss.
                </Endorsement>
              </Col>
              <Col xs={12} lg={6}>
                <Endorsement imageUrl="/endorsements/Bob-Lepine.jpg" name="Bob Lepine" bio={<span>cohost, <em>FamilyLife Today</em></span>}>
                  Most of us don't stop to consider how vital, how essential hope is&ndash;until we feel it starting to slip away. Ron Hutchcraft has learned 
                  how to fight for hope and where to look when hope is hard to see. He has given all of us a great gift here. This book will breathe fresh hope 
                  into even the most discouraged heart.
                </Endorsement>
              </Col>
            </Row>}
            {this.state.showMore===true && <Row>
              <Col xs={12} lg={6}>
                <Endorsement imageUrl="/endorsements/Ted-Cunningham.jpg" name="Ted Cunningham" bio={<span>pastor, Woodland Hills Family Church, author of <em>Fun Loving You</em></span>}>
                  Every time I hear or read Ron Hutchcraft, I know Jesus is at the center of the message. He is always pointing us to Jesus. This book is 
                  another great example of this. You'll be inspired to anchor your faith in Jesus through every storm.
                </Endorsement>
              </Col>
              <Col xs={12} lg={6}>
                <Endorsement imageUrl="/endorsements/Grant-Skeldon.jpg" name="Grant Skeldon" bio={<span>Next Gen director for Q, author of <em>The Passion Generation</em></span>}>
                  I think the integrity of Ron Hutchcraft and the timeliness of this book is no coincidence. If there's one thing we need right now, it's hope. 
                  Thank you for leading us to the only one who can give us hope, Jesus.
                </Endorsement>
              </Col>
            </Row>}
            {this.state.showMore===true && <Row>
              <Col xs={12} lg={{ span: 6, offset: 3 }}>
                <Endorsement imageUrl="/endorsements/Emma-Mae-Jenkins.jpg" name="Emma Mae Jenkins" bio={<span>author of <em>Be Loved</em> and <em>All Caps YOU</em>, Gen Z social media influencer/speaker</span>}>
                  Ron Hutchcraft acknowledges the hardship of pain and suffering while also encouraging that despair does not have to be the end of the story. 
                  Because of the gospel, we do not have to succumb to despair, but we have the authority in Jesus to walk in hope that cannot be shaken.
                </Endorsement>
              </Col>
            </Row>}

            {this.state.showMore===true && <Row>
              <Col className="text-center mt-3">
                <a href="#endorsements" className="showMore" onClick={this.onShowLess}>SHOW LESS</a>
              </Col>
            </Row>}

          </Container>
      </section>
    );
  }
}
