import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import Scroller from "./scroller";

export default class Cover extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
  }

  render() {
    return (
      <StaticQuery query={query} render={data => (
        <section id="cover">
          <div className="wave">
            <img src="/wave.png" alt="" />
          </div>          
          <Container>
            <Row>
              <Col xs={12} md={6} className="pb-5">
                <Img className="bookCover" alt="Hope When Your Heart Is Breaking" fluid={data.cover.childImageSharp.fluid} />
              </Col>
              <Col xs={12} md={6} className="coverText1">
                <div className="coverTextHolder">
                  <h1>IF YOU'VE EVER EXPERIENCED LOSS&hellip;</h1>
                  <p className="mb-2">
                    You've lost someone you love. Or you're on the brink of losing your marriage.
                    Your dreams. Your health. Or perhaps the trauma of your past pursues you into the present.
                    Your life's going to change. Which way it goes won’t be decided by your loss, but by the choices you make.
                  </p>
                  <h6>Losing means grieving.</h6>
                  <h6>Grieving means choices.</h6>
                  <h6>Choices mean hurt or healing.</h6>
                  <div className="my-4 text-center">
                    <Button className="px-4" href="#order" onClick={Scroller.handleAnchorScroll}>ORDER NOW</Button>
                    <div className="text-center pt-4">Now available: <br/><a className="audioLink" href="https://www.amazon.com/Hope-When-Your-Heart-Breaking/dp/B08VF3R59G/ref=zg_bsnr_7259412011_18?_encoding=UTF8&psc=1&refRID=VRKH7T46BYNHJ8RHMZQH" target="__blank">Audible Audiobook – Unabridged <br/>(read by Ron Hutchcraft)</a></div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Container fluid className="coverText2">
            <Row>
              <Col>
                <div className="coverTextHolder">
                  <h1>IF YOU'VE EVER EXPERIENCED LOSS&hellip;</h1>
                  <p className="mb-2">
                    You've lost someone you love. Or you're on the brink of losing your marriage.
                    Your dreams. Your health. Or perhaps the trauma of your past pursues you into the present.
                    Your life's going to change. Which way it goes won’t be decided by your loss, but by the choices you make.
                  </p>
                  <h6>Losing means grieving.</h6>
                  <h6>Grieving means choices.</h6>
                  <h6>Choices mean hurt or healing.</h6>
                  <div className="my-4 text-center">
                    <Button className="px-4" href="#order" onClick={Scroller.handleAnchorScroll}>ORDER NOW</Button>
                    <div className="text-center pt-4">Now available: <br/><a className="audioLink" href="https://www.amazon.com/Hope-When-Your-Heart-Breaking/dp/B08VF3R59G/ref=zg_bsnr_7259412011_18?_encoding=UTF8&psc=1&refRID=VRKH7T46BYNHJ8RHMZQH" target="__blank">Audible Audiobook – Unabridged <br/>(read by Ron Hutchcraft)</a></div>
                  </div>
                </div>
              </Col>
            </Row>            
          </Container>
        </section>
      )} />
    );
  }
}

export const query = graphql`
  query {
    cover: file(relativePath: { eq: "cover.png" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
