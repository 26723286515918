import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames";

export default class VimeoEmbed extends React.Component {
  render() {
    return (
      <div className={classNames(this.props.className, "responsiveVideo", this.props.useCinema ? "aspect241by100" : "aspect16by9")}>
        {this.props.vimeoId &&
          <iframe title={this.props.title} src={"https://player.vimeo.com/video/" + this.props.vimeoId} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
        }
        {!this.props.vimeoId &&
          <div className="comingSoonHolder"><div className="comingSoon">COMING SOON&hellip;</div></div>
        }
      </div>
    )
  }
}

VimeoEmbed.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  vimeoId: PropTypes.string,
  useCinema: PropTypes.bool,
}
